<template>
  <div class="card">
    <div class="card-body">
      <h4 class="card-title">Analytics</h4>
      <div :class="`alert mt-4 alert-${has_default_sender_id ? 'success' : 'danger'}`">
        <i class="lni-information alert-icon"></i>
        <template v-if="has_default_sender_id">Your current default sender ID is {{ default_sender_id }}</template>
        <template v-if="! has_default_sender_id">Your application has no sender ID. <router-link :to="{ name: 'app.bulk-sms.sender-id' }">Request or select one</router-link> to continue.</template>
      </div>
      <div class="alert mt-4 alert-danger" v-if="environment != 'live'">
        <i class="lni-information alert-icon"></i>
        <template v-if="environment != 'live'">Your app is in sandbox. <router-link :to="{ name: 'app.environment' }">Go live</router-link> to start sending real messages.</template>
      </div>
      <div class="row mt-5" v-if="environment == 'live'">
        <div class="col-md-7">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title mb-1">Bulk SMS report</h5>
              <p class="text-muted mb-4">Last 30 days</p>
              <line-chart :data="chart_data" />
            </div>
          </div>
        </div>
        <div class="col-md-5">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title mb-1 text-primary">Messages sent today</h5>
              <h2>{{ statistics.today }}</h2>
            </div>
          </div>
          <div class="card my-3">
            <div class="card-body">
              <h5 class="card-title mb-1 text-primary">Last 30 days</h5>
              <h2>{{ statistics.month }}</h2>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <h5 class="card-title mb-1 text-primary">All Time</h5>
              <h2>{{ statistics.all }}</h2>
            </div>
          </div>
        </div>
      </div>
      <div class="card mt-4" v-if="environment != 'live'">
        <div class="card-body">
          Go live to see your data.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch()
  },

  data() {
    return {
      has_default_sender_id: null,
      default_sender_id: '',
      environment: null,
      statistics: {},
      chart_data: {
        labels: [],
        datasets: []
      }
    }
  },

  methods: {
    fetch() {
      this.$loader.start('main-content')

      this.$axios.get(`/api/v1/apps/${this.$route.params.id}/bulk-sms-analytics`).then(response => {
        this.has_default_sender_id = response.data.has_default_sender_id
        this.default_sender_id = response.data.default_sender_id
        this.environment = response.data.environment
        this.statistics = response.data.statistics
        this.chart_data = response.data.chart_data
        this.$loader.stop()
      })
    }
  }
}
</script>